
.pbt50 {
  padding: 50px 0px;
}
.atractions {
  padding: 100px 50px;
  box-sizing: border-box;
}
.noItems{
  width: 40%;
  margin: auto;
}

.atractionsLink {
  width: 100%;
  height: 240px;
  font-size: 30px;
  text-decoration: none;
  font-weight: bold;
  /* border: 1px solid var(--white); */
  display: flex;
  align-items: flex-end;
  justify-content: center;
  border-radius: var(--radius);
  color: var(--white);
  padding-bottom: 10px;
  background-repeat: no-repeat;
  background-size: cover;
  transition: 0.5s;
  background-position-y: center;
}
.atractionsLink:hover {
  transform: translateY(-5px) scale(1.005) translateZ(0);
  box-shadow: var(--color) 0px 25px 20px -20px;
}
.reservationWrapper {
  border-radius: var(--radius);
  height: 100%;
  width: 100%;
  padding: 40px 0px;
}
.bckBtn{
  width: 40px;
  height: 40px;
  border: 1px solid var(--white);
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
@media only screen and (max-width: 900px) {
  .noItems{
    width: 100%;
    padding:50px 0px;
  }
}
@media only screen and (max-width: 600px) {
  
  .noItems{
    padding:0px;
  }
  .atractions{
    padding-top: 80px !important;
  }
  .atractionsLink{
    font-size:20px;
    background-position-x: center;
    height: 180px;
  }
}
