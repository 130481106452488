.detailsWrapper {
  background-color: var(--background);
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
  font-family: "Poppins", sans-serif;
}
.eliWrapper{
  /* background-color:#F03C44; */
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
  font-family: "Poppins", sans-serif;
  background-color: #28006B;
}

.detailsWrapper::before {
  content: "";
  position: absolute;
  display: inline-block;
  width: 80%;
  height: 200vh;
  margin-right: 5px;
  background: radial-gradient(
    ellipse at left top,
    #af46fa 5%,
    #af46fa 25%,
    var(--background) 50%,
    var(--background) 80%
  );
  border-radius: 100%;
  top: -200px;
  left: -450px;
  opacity: 1;
  z-index: -1;
}
/* .eliWrapper::before{
  content: "";
  position: absolute;
  display: inline-block;
  width: 80%;
  height: 200vh;
  margin-right: 5px;
  background: radial-gradient(
    ellipse at left top,
    #F03C44 5%,
    #F03C44 25%,
    var(--background) 50%,
    var(--background) 80%
  );
  border-radius: 100%;
  top: -200px;
  left: -450px;
  opacity: 1;
  z-index: -1;
}
.organeWrapper::before{
  content: "";
  position: absolute;
  display: inline-block;
  width: 80%;
  height: 200vh;
  margin-right: 5px;
  background: radial-gradient(
    ellipse at left top,
    #FAA000 5%,
    #4e3201 25%,
    var(--background) 50%,
    var(--background) 80%
  );
  border-radius: 100%;
  top: -200px;
  left: -450px;
  opacity: 1;
  z-index: -1;
} */
.eliWrapper a,
.eliWrapper h2{
  /* border-color: #F03C44 !important; */
  color: #F03C44;
}
.eliWrapper a{
  color: var(--white) !important;
}

.container {
  width: 1024px;
  margin: auto;
  justify-content: space-between;
  color: var(--white);
  overflow: hidden;
}

.sectionContainer {
  padding-top: 100px;
  color: var(--white);
}

.cardsWrapper {
  gap: 51px;
  display: flex;
  flex-wrap: wrap;
  padding-top: 100px;
}

.smallConGap {
  padding-top: 20px;
}

.GridTxtGallery {
  gap: 20px;
  display: flex;
  flex-wrap: wrap;
  margin-top: 78px;
}

.GridTxtGallery :nth-child(1) {
  width: 59%;
}

.GridTxtGallery :nth-child(2) {
  width: 39%;
}

.GridTxtGallery :nth-child(3) {
  width: 100%;
}

.sportsBarWrapper {
  color: #fff;
  width: 100%;
  position: relative;
}

.sportsBarWrapper::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  margin-right: 5px;
  background: linear-gradient(
    45deg,
    rgba(50, 30, 255, 1) 0%,
    rgba(45, 140, 255, 1) 5%,
    rgba(50, 32, 255, 1) 20%,
    rgba(175, 70, 250, 1) 59%,
    rgba(50, 30, 255, 1) 100%
  );
  opacity: 1;
  z-index: -1;
  top: 0;
  left: 0;
}

.cardsArcadeWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 13px;
}

.minSectionContainer {
  padding-top: 50px;
}

.xsSectionContainer {
  padding-top: 20px;
}

.minWidth {
  width: 80%;
  margin: auto;
}

.costumWrap {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}
.prep h2{
  color: var(--main);
  letter-spacing: -0.75px;
  font-size: 26px;

}
.prep p{
  color: #E4E4E4;
  letter-spacing: -0.52px;
  font-weight: 300;
}
.moreOptions{
  background-color: #120E2E;
  width: 60%;
  padding:40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: var(--radius);
  margin:50px auto;
  box-sizing: border-box;
}
.moreOptions h1{
  color: var(--color);
  margin: 0px;
  padding-bottom: 20px;
}
.moreOptions p{
  font-size: 12px;
  text-align: center;
  color: #E4E4E4;
}
.eliLogo{
  margin: auto;
  margin-top: 100px;
  width: 350px;
}
.eliLogo img{
  width: 100%;
}
.wrapper{
    margin-top: 100px !important;
}
@media only screen and (max-width: 1281px) {
  /* hi-res laptops and desktops */
}

@media only screen and (max-width: 1025px) {
  /* big landscape tablets, laptops, and desktops */

  .container {
    width: 80%;
  }

  .GridTxtGallery {
    justify-content: space-between;
    row-gap: 20px;
    column-gap: unset;
  }

  .GridTxtGallery :nth-child(1) {
    width: 49%;
  }

  .GridTxtGallery :nth-child(2) {
    width: 49%;
  }

  .costumWrap {
    display: flex;
    row-gap: 20px;
    column-gap: unset;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

@media only screen and (max-width: 801px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */

  .moreOptions{
    width: 100%;
  }
  .container {
    width: 90%;
  }
  
  .sectionContainer {
    padding-top: 50px;
  }

  .costumWrap {
    display: flex;
    row-gap: 20px;
    column-gap: unset;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .cardsArcadeWrapper {
    justify-content: space-between;
  }

  .GridTxtGallery {
    justify-content: space-between;
    row-gap: 20px;
    column-gap: unset;
  }

  .GridTxtGallery :nth-child(1) {
    width: 49%;
  }

  .GridTxtGallery :nth-child(2) {
    width: 49%;
  }
}

@media only screen and (max-width: 600px) {
  .container {
    width: 80%;
  }

  .cardsArcadeWrapper {
    gap: 20px;
    flex-direction: column;
  }

  .GridTxtGallery :nth-child(1) {
    width: 100%;
  }

  .GridTxtGallery :nth-child(2) {
    width: 100%;
  }
}
