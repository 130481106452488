.cartWrapper p,
.cartContainer h2 {
    margin: 0px;
}

.cartWrapper span {
    font-size: 12px;
    text-align: center;
    color: #e4e4e4;
}

.cartBox h1 {
    width: max-content;
    margin: 0px;
    padding: 5px 0px;
    color: #fff;
    font-size: 20px;
    font-weight: 300;
}

.cartHeader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 20px;
}

.center {
    text-align: center;
}

.cartHeader h2,
.cartHeader p {
    margin: 0px;
    text-align: center;
}

.cartFooter {
    padding: 20px 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    color: #e4e4e4;
}

.cartFooter+span {
    font-size: 11px;
    text-align: center;
}

.close {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.close+h1 {
    font-size: 15px;
}

.close svg {
    width: 100%;
    width: 30px;
    height: 30px;
    color: #f03c44;
}

.more {
    font-size: 13px;
    color: var(--white);
    padding: 10px 0px;
    text-decoration: none;
}

.emptyCart {
    background-color: #120f2e;
    padding: 30px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.emptyCart p {
    font-size: 15px;
    padding: 5px 0px;
    margin: 0px;
}

.cartBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px dashed #918db8;
    padding: 10px;
    width: 100%;
}

.reserveNow {
    padding: 100px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.reserveNow h1 {
    margin: 0px;
}

.codeCoupon {
    display: flex;
    width: 100%;
    margin: 20px 0px;
    flex-direction: column;
    justify-content: space-between;
}

.codeCoupon button {
    margin-top: 10px;
}

.error {
    font-size: 13px;
    font-weight: bold;
    color: var(--red);
    padding: 5px;
}

.coupon {
    width: 100%;
    font-size: 13px;
    font-weight: bold;
    color: #faa000 !important;
    padding: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-transform: uppercase;
    padding: 20px 0px;
}

.coupon span {
    font-size: 11px !important;
    opacity: 0.5;
}

@media only screen and (max-width: 900px) {
    .reserveNow p {
        text-align: center;
    }
}