

.checkout{
  padding: 0px;
  margin: 30px 0px;
  width: 100%;
  border-collapse: collapse;
}
.checkout thead{
  background-color: #120f2e;
}
.checkout thead th{
  padding:10px;
  text-align: left;
  font-size: 13px;

}
.checkout td{
  padding:10px;

}
.checkout tbody td{
  color: #E4E4E4;
}
.checkoutHeader h1,
.checkoutHeader p{
  margin: 0px;
  padding: 0px;
}
.terms{
  font-size: 13px;
  display: flex;
  margin: 10px 0px;;
  display: flex;
}
.terms a{
  color: var(--orange);
  font-weight: bold;
  padding-left: 5px;
  text-decoration: none;
}
.mobileCart{
  display: none;
}
.close {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.close + h1 {
  font-size: 12px;
}
.close svg {
  width: 100%;
  width: 30px;
  height: 30px;
  color: #f03c44;
}

@media only screen and (max-width: 900px) {
  .mobileCart{
    padding:5px 20px; 
    border-radius: var(--radius);
    background-color: #120f2e;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
    font-size: 14px;
    margin-bottom:10px;
  }
  .cartContainer{
    /* display: none; */
    padding:20px;
  }
  .noItems{
    width: 100%;
  }
}
@media only screen and (max-width: 600px) {
  .checkoutWrapper{
    padding:0px 20px;
  }
  .terms {
    flex-direction: column;
    align-items: start;
    
  }
}
