.textWrapper{
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: auto;
  padding-bottom: 50px;
}
.textWrapper h1{
  margin: 0px;
  font-weight: bold;
  font-size: 40px;
}
.textWrapper p{
  text-align: center;
  font-size: 25px;
  padding: 30px 0px;
  white-space: pre-line;
}
@media only screen and (max-width: 600px) {
 
  .textWrapper{
    text-align: left;
    width: 100%;
  }
  .textWrapper h1{
    font-size: 25px;
  }
  .textWrapper p{
    font-size: 18px;
    text-align: left;
  }
}