.singleCard{
  width: 400px;
  margin:auto;
}
.cardHistory{
  list-style: none;
  padding: 20px 0px;
  margin: 0;
}
.cardHistory li{
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  padding-bottom: 20px;

}
.cardHistory li p,
.cardHistory li span{
  margin: 0px;
  padding: 0px;
}
.refillBox{
  border: 1px solid #321EFF;
  border-radius: var(--radius);
  width: 215px;
  height: 171px;
  margin: auto;
  height: 170px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 45px;
  cursor: pointer;
  letter-spacing: 0.85px;
  transition: 0.5s;
}
.refillBox:hover,
.refillBoxActive{
  background-color: #321EFF;
}
.refillBtn{
  display: flex;
  padding:20px 0px;
  justify-content: flex-end;
}