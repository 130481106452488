.eventBannerContainer {
  display: flex;
  overflow: hidden;
  position: relative;
  border-radius: 27px;
  background-color: #120f2e;
  min-height: 350px;
}

.eventBannerImage {
  min-width: 50%;
  width: 50%;
}

.eventBannerImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: var(--radius);
}
.eventBannerContainer p {
  /* font-size: 13px;
  font-weight: 300;
  color: #e4e4e4;
  margin-bottom: 25px; */
}
.eventBannerContainer a{
  border: 1px solid var(--white);
  text-decoration: none;
  padding:10px;
  color: var(--white);
  border-radius: var(--radius);
  width: fit-content;
}
.eventBannerInfo {
  /* width: 50%;
  display: flex;
  padding: 20px 50px;
  flex-direction: column; */
  /* justify-content: space-evenly; */
}

.eventBannerInfo h1 {
  /* /* line-height: 1.2; */
  letter-spacing: 1.2px;
  font-weight: bold;
  /* font-size: 25px; */
  margin: 10px 0 15px 0;
}

.linkBtn{
  display: flex;
  padding: 15px;
  color: #FFF;
  cursor: pointer;
  width: fit-content;
  align-items: center;
  border-radius: 35px;
  margin-bottom: 20px;
  text-decoration: none;
  justify-content: center;
  background: transparent;
  border: solid 1px #FFF;
  font-size:13px
}

.linkBtn span{
  display: flex;
  font-size: 24px;
  margin-left: 5px;
  color: #F03C44;
}

@media only screen and (max-width: 1025px) {
  /* .eventBannerInfo { */
    /* padding: 20px; */
  /* } */

  .eventBannerImage {
    max-width: 50%;
  }
}

@media only screen and (max-width: 800px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */

  .eventBannerImage {
    width: 100%;
    opacity: 0.3;
    height: 100%;
    max-width: 100%;
    position: absolute;
  }

  /* .eventBannerInfo {
    z-index: 1;
    width: 100%;
    padding: 20px;
    max-width: 100%;
  } */
}
