.miniBanner {
  height: 180px;
  width: 100%;
  background-size: cover;
}
.priceLayout {
  background-color: #120e2e;
  padding: 30px;
}
.priceLayout p {
  /* width: 50%; */
}
.priceLayout:nth-child(2n) {
  background-color: transparent;
}
.iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  margin: 0px 10px;
  position: relative;
  border: 1px solid var(--white);
  width: 40px;
  height: 40px;
  border-radius: 100%;
  color: var(--white);
}

.spaceBetween,
.spaceBetween div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.priceWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  position: relative;
}
/* .priceWrapper::before {
  content: "";
  position: absolute;
  display: inline-block;
  width: 80%;
  height: 2px;
  left: 90px;
  border-radius: 100%;
  background-color: #918db8;
  opacity: 1;
  z-index: 1;
} */
.priceWrapper div {
  display: flex;
  flex-direction: column;
}
.priceWrapper div span:last-child {
  font-size: 13px;
}
.ruleBox{
  width: 80%;
}
.ruleBox span{
  font-size: 35px;
  font-weight: bold;
}
.ruleBox h1{
  font-size: 20px;
}
.ruleBox p{
  color: #E4E4E4;
  font-size: 13px;
}
@media only screen and (max-width: 800px) {
  .spaceBetween {
    flex-direction: row;
    align-items: start;
  }
  .spaceBetween h1{
    margin: 0px;
  }
  .spaceBetween p{
    width: 100%;
  }
  .priceLayout{
    padding: 20px 0px;
    font-size: 15px;
  }
  .priceWrapper{
    margin-bottom: 10px;
  }
  .priceWrapper::before {
    display: none;
  }
}
