header {
    z-index: 999;
    width: 100%;
    position: fixed;
    display: flex;
    flex-direction: column;
}

.header {
    padding: 10px 0px;
    background-color: var(--black);
}

.header>div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.headerList {
    min-width: max-content;
    align-items: center;
    display: flex;
    padding: 10px 0px;
    justify-content: flex-end;
}

.headerList ul {
    list-style: none;
    display: flex;
    align-items: center;
    list-style: none;
}

.headerList ul li {
    padding: 0px 10px;
    color: var(--white);
}

.headerList .btn {
    background-color: transparent !important;
}

.resrverBtn {
    background-color: transparent;
    padding: 10px 35px;
    border-radius: var(--radius);
    color: white;
    outline: none;
    text-decoration: none;
    font-size: 14px;
    width: -moz-fit-content;
    width: fit-content;
    cursor: pointer;
    border: none;
    border: 1px solid var(--color);
    transition: 0.5s;
}

.resrverBtn :hover {
    background-color: var(--color);
}

.headerList ul li a,
.blueHeader ul li a {
    text-decoration: none;
    transition: 0.5s;
    font-size: 16px;
    color: var(--white);
}

.headerList ul li a:hover {
    color: var(--color);
}

.blueHeader {
    /* background: linear-gradient(90deg, rgba(0,99,255,1) 5%, rgba(0,99,255,1) 17%, rgba(27,25,199,1) 49%, rgba(27,25,199,1) 100%); */
    background-color: #00dbde;
    background-image: linear-gradient(90deg,
            rgba(0, 99, 255, 1) 0%,
            var(--main) 100%);
}

.blueHeader ul {
    display: flex;
    padding: 0px;
    margin: 0px;
    list-style: none;
    justify-content: space-between;
}

.blueHeader ul li {
    display: flex;
    align-items: center;
    justify-content: center;
}

.blueHeader ul li a {
    font-size: 16px;
    padding: 10px 0px;
}

.login {
    position: relative;
}

.loginInfo {
    display: flex;
    align-items: center;
    justify-content: center;
}

.loginInfo>div {
    background-color: transparent;
    border: 1px solid var(--white);
    color: var(--color);
    border-radius: var(--radius);
    color: white;
    text-decoration: none;
    font-size: 14px;
    width: fit-content;
    cursor: pointer;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
    transition: 0.5s;
}

.login>a:hover {
    border-color: var(--color);
}

.login>a:hover .loginMenu {
    /* display: flex; */
}

.loginMenu {
    position: absolute !important;
    background-color: var(--white);
    padding: 5px;
    bottom: -125px;
    border-radius: 10px;
    /* display: none; */
    z-index: 999;
}

.loginMenuMob {
    width: fit-content;
    box-sizing: border-box;
    position: absolute;
    background-color: var(--white);
    display: flex;
    align-items: left;
    flex-direction: column;
    border-radius: 10px;
    padding: 10px;
    color: var(--black);
    bottom: -10px;
    left: -30px;
    text-align: left;
    justify-content: flex-start !important;
    align-items: flex-start !important;
    font-weight: bold;
}

.loginMenuMob a,
.loginMenuMob span {
    color: var(--black);
    text-decoration: none;
    font-weight: bold;
    padding: 10px;
}

.loginMenu ul {
    padding: 5px 0px;
    margin: 0px;
    list-style: none;
    display: flex;
    flex-direction: column;
    color: var(--black);
}

.loginMenuOpen {
    display: flex;
}

.loginMenu ul li {
    padding: 5px;
    width: 150px;
}

.loginMenu ul li a,
.loginMenu ul li {
    color: var(--black);
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
}

.headerMobile {
    /* position: fixed;
  top: 0;
  bottom: 0;
  background-color: var(--black);
  width: 100%;
  box-shadow: 0px 6px 9px rgb(0 0 50 / 6%);
  font-size: 13px;
  z-index: 1; */
    background-color: var(--black);
    z-index: 9999;
    position: fixed;
    width: 100%;
}

.headerMobileTop {
    display: flex;
    top: 0;

    justify-content: space-between;
    border-bottom: 1px solid var(--gray);
}

.iconsHeader li {
    width: 45px;
    margin: 0px 5px;
    height: 45px;
    background-color: var(--gray);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    padding: 0px;
}

.iconsHeader>div {
    width: 40px;
    height: 40px;
}

.flexList {
    display: flex;
    list-style: none;
}

.cartQty {
    position: absolute;
    width: 18px;
    height: 18px;
    background-color: var(--red);
    color: var(--white);
    font-size: 13px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -10px;
    left: 20px;
}

.flexList li {
    margin-left: 10px;
    border: 1px solid var(--color);
    width: 45px;
    height: 45px;
    border-radius: var(--radius);
    position: relative;
}

.flexList li>span,
.flexList li>a {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.flexList li a,
.flexList li div {
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
}

.flexList li svg {
    width: 20px;
}

.iconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    margin: 0px 10px;
    position: relative;
    border: 1px solid var(--white);
    width: 35px;
    height: 35px;
    border-radius: 100%;
    color: var(--white);
}

.cardContainer span {
    position: absolute;
    width: 18px;
    height: 18px;
    background-color: var(--red);
    font-size: 13px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -3px;
    left: 20px;
}

.cardContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    margin: 0px 10px;
    position: relative;
    width: 35px;
    height: 35px;
    border-radius: 100%;
    color: var(--white);
    border: solid 1px #fff;
    display: flex;
}

.cardContainer svg {
    right: 1px;
    position: relative;
}

.lng {
    display: flex;
    color: #6d65a1;
    cursor: pointer;
}

.lng span:hover {
    transition: 0.5s;
    color: var(--white);
    font-weight: bold;
}

.lng .active {
    transition: 0.5s;
    color: var(--white);
    font-weight: bold;
}

.mobileHeader {
    position: fixed;
    /* top: 65px; */
    width: 100%;
    background-color: var(--black);
    height: 100vh;
    transition: all 0.2s ease-in-out;
    -webkit-animation: movedown 0.5s linear forwards;
    -moz-animation: movedown 0.5s linear forwards;
    -ms-animation: movedown 0.5s linear forwards;
    -o-animation: movedown 0.5s linear forwards;
    animation: movedown 0.5s linear forwards;
    padding: 0;
    opacity: 0;
    visibility: hidden;
}

.openMenu {
    opacity: 1;
    visibility: visible;
}

.mobileHeader ul {
    list-style: none;
    padding: 0px;
}

.mobileHeader li {
    padding: 10px 0px;
    -webkit-animation: translate-title 2s linear;
    -webkit-animation-fill-mode: forwards;
    animation: translate-title 2s linear;
    animation-fill-mode: forwards;
    text-align: left;
    margin: 0;
    padding: 0;
    opacity: 0;
    transform: translateX(-100%);
    transition: all 0.5s ease-in-out;
    transition-delay: 0.1s;
}

.mobileHeader li:nth-child(2n) {
    transition-delay: 0.2s;
}

.mobileHeader li:nth-child(3n) {
    transition-delay: 0.3s;
}

.fadeIn {
    opacity: 1 !important;
    transform: translateX(10px) !important;
}

.mobileHeader a {
    color: var(--white);
    text-decoration: none;
    font-size: 24px;
    padding: 10px 0px;
    display: flex;
}

.mobileNav {
    display: flex;
    flex-direction: row;
    bottom: 150px;
    width: 100%;
}

.mobileNav a {
    font-size: 12px;
    margin: 5px;
    width: 50%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mobileNav>a {
    border: 1px solid var(--color);
    padding: 10px;
    margin-bottom: 20px;
    border-radius: var(--radius);
}

.pointer {
    cursor: pointer;
}

.lngWrapper {
    display: flex;
    list-style: none;
    margin: 0px;
    background-color: var(--color);
    justify-content: flex-end;
}

.lngWrapper li {
    padding-right: 20px;
    color: rgba(255, 255, 255, 0.6);
}

.lngWrapper .active {
    color: var(--white);
}

@-webkit-keyframes translate-title {
    0% {
        min-width: 100%;
    }

    100% {
        min-width: 0;
    }
}

@keyframes translate-title {
    0% {
        min-width: 100%;
    }

    100% {
        min-width: 0;
    }
}