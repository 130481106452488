.loginModal{
  position: absolute;
  width: 100%;
  overflow: hidden;
  /* height: 100%; */
  z-index: 9999;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  height: auto;
  background-color: rgba(0, 0, 0,0.8);
}
.loginWrapper{
  width: 500px;
  padding:40px 20px;
  border-radius: 10px;
  margin: auto;
  background-color: #020013;
  border: 1px solid var(--color);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}
.closeBtn{
  position: absolute;
  width: 50px;
  height: 50px;
  top: 10px;
  font-size: 15px;
  right: 10px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  cursor: pointer;
  background-color: #120f2e;

}
.loginWrapper a{
  width: 100%;
  box-sizing: border-box;
  padding: 15px 80px;
}
.loginWrapper a{
  text-align: center;
  color: var(--color);
  text-decoration: none;
  font-weight: bold;
  font-size: 13px;
}
.loginMessage{
  color: var(--white);
  font-weight: bold;
  width: 100%;
  text-align: left;
  font-size: 15px;
  padding-left: 15px;
  margin: 0px;
  padding-top: 20px;
}
.loginForm{
  width: 500px;
  display: flex;
  flex-direction: column;
  margin: auto;
}
.loginForm a{
  color: var(--white) !important;
}
.warningMessage{
  color: rgb(45, 140, 255);
  position: relative;
  border-radius: var(--radius);
  margin: 10px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  font-size: 14px;
}
.customLink{
  text-transform: uppercase;
  color: var(--white) !important;
  font-weight: bold;
  letter-spacing: 1.1px;
  font-size: 15px;
}

.center{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.center button{
  margin:10px 0px;
  width: 100%;
  cursor: pointer;
}
.linkBtn{
  background-color: transparent;
  outline: none;
  color: var(--white);
  border: none;
  margin: 0px !important;
}
@media only screen and (max-width: 800px) {
  .loginForm {
    width: 100%;
  }
}