.registerForm{
    display: flex;
    flex-direction: column;
}
.registerForm  button[type="submit"] {
  width: 60%;
  margin: 30px auto;
}
.inputInfo {
  font-size: 13px;
  padding-left: 10px;
  margin: 0px;
  color: #e4e4e4;
  display: flex;
  align-items: center;
}
.termsWrapper {
  width: 60%;
  padding: 10px 0px;
  margin: auto;
}
.termsWrapper div {
  margin: 10px 0px;
  display: flex;
  align-items: baseline;
}
.termsWrapper label {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 12px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
 .error {
  border:1px solid  var(--red) !important;
}
.termsWrapper input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
/* On mouse-over, add a grey background color */
.termsWrapper label:hover input ~ .checkmark {
  background-color: #120f2e;
}

/* When the checkbox is checked, add a blue background */
.termsWrapper label input:checked ~ .checkmark {
  background-color: #321eff;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.termsWrapper label input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.termsWrapper label .checkmark:after {
  left: 9px;
  top: 8px;
  width: 3px;
  height: 6px;
  border: solid #03001e;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #120f2e;
}


@media only screen and (max-width: 800px) {
  .termsWrapper {
    width: 100%;
  }
}