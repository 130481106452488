.footer {
  padding: 100px 0px;
  width: 1024px;
  margin: auto;
  justify-content: space-between;
  color: var(--white);
  overflow: hidden;
}

.mapouter {
  position: relative;
  text-align: right;
  width: 100%;
  height: 400px;
  margin: 50px 0px;
}
.gmap_canvas {
  overflow: hidden;
  background: none !important;
  width: 100%;
  height: 400px;
  border-radius: var(--radius);
}
.gmap_iframe {
  height: 400px !important;
}

.footer span {
  font-size: 13px;
  color: #e4e4e4;
}
.footer p,
.footer a {
  margin: 0px;
  font-weight: bold;
  font-size: 18px;
}
.social{
  display: flex;
  padding: 0px;
  list-style: none;
}
.social a{
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--main);
  margin-right: 10px;
  border-radius: var(--radius);
}
.mail{
  display: flex;
  flex-direction: column;
}
.mail a{
  color: var(--white);
  text-decoration: none;
}
@media only screen and (max-width: 1040px) {
  .footer {
    width: 100%;
    box-sizing: border-box;
    padding: 50px 20px 0 20px;
  }
  .mapouter div {
    max-width: 100%;
  }
}
