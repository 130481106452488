.videoWrapper {
  display: flex;
  height: 450px;
  overflow: hidden;
  align-items: flex-end;
  position: relative;
  margin-bottom: 50px;
  border-radius: var(--radius);
}
.videoWrapper img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: var(--radius);
  z-index: 1;
}
.videoWrapper > div {
  z-index: 2;
  color: var(--white);
  padding: 20px;
  bottom: 0;
  display: flex;
  flex-direction: column;
  box-shadow: #000000b5 0px -80px 44px -30px inset;
}

.videoWrapper div div {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.videoWrapper div h1 {
    font-size: 52px;
    width: 506px;
    margin: 0;
  }

.videoWrapper div div p {
  width: 50%;
  font-size: 20px;
}

@media only screen and (max-width: 600px) {
  .videoWrapper,
  .videoWrapper div div {
    flex-direction: column;
    align-items: start;
  }
  
  .videoWrapper div h1 {
    font-size: 30px;
    width: 100%;
  }

  .videoWrapper div div p{
    width: 100%;
  }

  .videoWrapper > div {
    width: calc(100% - 20px);

  }
}