.optionUser {
  display: flex;
  list-style: none;
  padding: 0px;
  width: fit-content;
  margin: 50px auto 0px auto;
}
.optionUser a {
  padding: 10px;
  margin-right: 10px;
  color: var(--white);
  text-decoration: none;
  color: #a8a8a8;
  font-weight: bold;
  transition: 0.5s;
}
.optionUser a:hover {
  color: var(--white);
}
.optionUser .active {
  border-bottom: 2px solid var(--color);
  color: var(--white);
}

@media only screen and (max-width: 600px) {
  
  .optionUser{
    margin: 80px auto 0px auto;
  }
}
