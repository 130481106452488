.borderWrapper {
  border-top: 1px solid #120e2e;
  border-bottom: 1px solid #120e2e;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.borderWrapper h2 {
  font-size: 18px;
  margin: 5px 0px;
}
.borderWrapper p {
  font-size: 13px;
  padding: 0px;
  margin: 0px;
  color: #e4e4e4;
}
.playerQuantity {
  display: flex;
}
.playerQuantity input {
  width: 50px;
  outline: none;
  background-color: transparent;
  color: white;
  border: none;
  text-align: center;
  font-size: 16px;
  -webkit-appearance: none;
  margin: 0;
  margin: 0;
}
.playerQuantity input::-webkit-outer-spin-button,
.playerQuantity input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.quantity {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color);
  border: none;
  outline: none;
  color: var(--white);
  border-radius: 100%;
  cursor: pointer;
  transition: 0.5s;
  border: 1px solid var(--color);
  display: flex;
  font-size: 20px;
  padding: 0px;
}
.quantity:hover {
  background-color: transparent;
  border-color: var(--color);
}
.priceList {
  font-size: 12px;
  padding:20px 0px;
}
.priceList a {
  color: var(--main);
  text-decoration: none;
  font-weight: bold;
  font-size: 14px;
  margin-left: 3px;
}
.playerData {
  margin-top: 40px;
}
.playerData p {
  font-size: 14px;
  margin: 0px;
  padding: 0px;
}
.border {
  padding-bottom: 5px;
  display: flex;
  align-items: left;
  flex-direction: column;
  justify-content: left;
}
.border>div{
  display: flex;
  flex-direction: row;
}
.border h2{
  margin: 0px;
}
.border span,
.border>div>a{
  width: 40px;
  height: 40px;
  border: 1px solid var(--white);
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  cursor: pointer;
  transition: 0.5s;
  color: var(--white);
}
.border span:hover{
  background-color: var(--color);
  border-color: var(--color);
}
.border p{
  margin: 0px;
}