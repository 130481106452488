.loginForm{
  width: 500px;
  padding:20px 0px;
  margin: auto;
}

@media only screen and (max-width: 800px) {
  .loginForm {
    width: 100%;
  }
}