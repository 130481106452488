.fullBannerContainer{
    width: 100%;
    color: #FFF;
    height: 100vh;
    display: flex;
    position: relative;
    align-items: center;
}

.fullBannerImg{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.fullBannerImg img {
    width: 100%;
    height: 100%;
    /* object-fit: cover; */
}

.fullBannerInfo{
    z-index: 1;
    margin: auto;
    width: 500px;
    display: flex;
    position: relative;
    flex-direction: column;
}

.fullBannerInfo h1 {
    margin-top: 0;
    font-size: 45px;
}

.fullBannerInfo p {
    width: 350px;
    white-space: pre-line;
}

.fullBannerInfo a {
    margin-top: 20px;
    padding: 15px 40px;
    width: fit-content;
    border-radius: 40px;
    text-decoration: none;
    background-color: #FAA000;
}