.linkCardContainer {
  border-radius: 20px;
  position: relative;
  height: 350px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  transition: 0.5s;
  overflow: hidden;
}
.linkCardContainer:hover {
  transform: translateY(-5px) scale(1.005) translateZ(0);
  box-shadow: var(--color) 0px 25px 20px -20px;
}

.linkCardContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.linkCardContainer h1 {
  margin: 0;
  left: 20px;
  bottom: 20px;
  font-size: 30px;
  position: absolute;
  color: var(--white);
}
