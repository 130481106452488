.carouselGalleryContainer{
    width: 100%;
}

.item{
    margin: 0 20px;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    background-color: #fff;
}
.item img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}