:root {
  --background: #060320;
  --black: #000;
  --white: #fff;

  --main: #af46fa;
  --color: #321eff;
  --red: #f07379;
  --orange: #faa000;
  --radius: 20px;
}
body {
  padding: 0px;
  background-color: #020013;
  margin: 0px;
  border-radius: var(--radius);
}
:global(.bodyWrapper) {
  padding: 140px 0px 0px 0px;
  /* padding: 97px 0px 0px 0px; */
}
:global(.custom-toaster) {
  z-index: 999999999999999999 !important;
  background-color: rgba(255, 255, 255, 0.1);
  color: var(--white);
  padding: 10px 10px;
  border-radius: var(--radius);
  margin: 10px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
}
:global(.errorMsg) {
  background-color: var(--red);
  padding: 5px !important;
  margin-bottom: 5px !important;
  display: flex;
  border-radius: 5px;
  font-size: 15px;
}
:global(.notifyMsg) {
  background-color: rgba(50, 30, 255, 0.4);
  padding: 5px !important;
  border-radius: 5px;
  font-size: 15px;
  margin: 10px 0px !important;
  border: 1px solid var(--color);
  display: flex;
  align-items: center;
  transition: 0.5s;
}
:global(.notifyMsg svg) {
  padding-right: 5px;
}
:global(.btn) {
  background-color: var(--color);
  padding: 10px 35px;
  border-radius: var(--radius);
  color: white;
  outline: none;
  text-decoration: none;
  font-size: 14px;
  width: fit-content;
  cursor: pointer;
  border: none;
  border: 1px solid var(--color);
  transition: 0.5s;
}
:global(.orangeBtn) {
  background-color: #faa000;
  padding: 10px 80px;
  border-radius: var(--radius);
  color: white;
  outline: none;
  text-decoration: none;
  font-size: 17px;
  width: fit-content;
  cursor: pointer;
  border: none;
  border: 1px solid #faa000;
  transition: 0.5s;
}
:global(.orgBtn) {
  background-color: #faa000;
  padding: 10px 30px;
  border-radius: var(--radius);
  color: white;
  outline: none;
  text-decoration: none;
  font-size: 14px;
  width: fit-content;
  cursor: pointer;
  border: none;
  border: 1px solid #faa000;
  transition: 0.5s;
  margin:20px 0px 0px 0px;
}
:global(.orangeBtn:hover) {
  background-color: transparent;
  color: #faa000;
}
:global(.btn:hover) {
  background-color: transparent;
  color: var(--color);
}
:global(.whiteBtn) {
  background-color: transparent;
  padding: 15px 25px;
  border-radius: var(--radius);
  border: 1px solid var(--white);
  color: white;
  outline: none;
  text-decoration: none;
  font-size: 14px;
  width: fit-content;
  align-items: center;
  display: flex;
  transition: 0.5s;
  font-weight: bold;
  cursor: pointer;
}
:global(.whiteBtn:hover) {
  background-color: var(--white);
  color: var(--black);
}
:global(.whiteBtn svg) {
  margin-left: 5px;
  fill: var(--red);
}
:global(.purpleBtn) {
  border: 1px solid var(--main);
  border-radius: var(--radius);
  color: white;
  padding: 10px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 12px;
}
:global(.purpleBtn:hover) {
  background-color: var(--main);
}
:global(.mt20) {
  margin-top: 20px;
}
:global(.container) {
  width: 1024px;
  margin: auto;
  justify-content: space-between;
  color: var(--white);
}
:global(.mb10) {
  margin-bottom: 10px;
}
:global(.mt20) {
  margin: 20px 0px;
}
:global(.pbt100) {
  padding: 100px 0px;
}
:global(.pbt200) {
  padding: 200px 0px;
}
:global(.pbt20) {
  padding: 20px 0px;
}
:global(.pbt50) {
  padding: 50px 0px;
}
:global(.pbt80) {
  padding: 80px 0px;
}
:global(.lottie) {
  width: 50%;
  margin: auto;
  text-align: center;
  font-size: 20px;
}

@media only screen and (max-width: 1040px) {
  :global(.container) {
    width: 100%;
    padding: 0px 20px;
    box-sizing: border-box;
    color: var(--white);
  }
  :global(.bodyWrapper) {
    /* padding: 40px 0px; */
    padding: 104px 0px;
  }
}

@media only screen and (max-width: 600px) {
  :global(h2) {
    font-size: 19px;
  }
  :global(h1) {
    font-size: 20px;
  }
  :global(p),
  :global(.errorMsg) {
    font-size: 13px;
    text-align: left;
  }
  :global(.errorMsg) {
    margin-bottom: 30px !important;
  }
  :global(.pbt100) {
    padding: 100px 0px;
  }
  :global(.lottie) {
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  :global(.lottie p) {
    font-size: 20px;
  }
}
