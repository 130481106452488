.errorMessages span,
.successMessages span,
.warningMessage span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: var(--radius);
  cursor: pointer;
  /* background: #c76c71; */
  transition: 0.5s;
}
.successMessages span {
  background-color: #abcaac;
}

.successMessages {
  background-color: #baf0bc;
  color: #358e38;
  padding: 7px 10px;
  position: relative;
  border-radius: var(--radius);
  margin: 10px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
}

.errorMessages span:hover {
  background-color: #b4676b;
}

.errorMessages {
  background-color: #dc757a;
  color: var(--white);
  padding: 5px 10px;
  position: relative;
  border-radius: var(--radius);
  margin: 10px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
}
.warningMessage {
  background-color: #ddc765;
  color: var(--white);
  padding: 5px 10px;
  position: relative;
  border-radius: var(--radius);
  margin: 10px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
}
.lottie {
  width: 300px;
  margin: auto;
  padding: 20px 0px;
}
.lottieBox{
  margin:50px 0px;
}
.lottieWrapper{
  background-color: #120f2e;
  padding:20px;
  border-radius: var(--radius);
  width: 50%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}
@media only screen and (max-width: 1100px) {
  .lottieWrapper {
    margin: 150px auto;
    width: 80%;
    box-sizing: border-box;
  }
}

@media only screen and (max-width: 800px) {
  .lottieWrapper {
    margin: 80px 0px;
    width: 100%;
    box-sizing: border-box;
  }
  .lottieWrapper p{
    text-align: center;
  }
}
