.spinnerContainer{
  width: fit-content;
  margin: auto;
  color: var(--white);
  text-align: center;
  padding:200px 50px;
}
.noPaddingSpinner{
  width: fit-content;
  margin: auto;
  color: var(--white);
  text-align: center;
}
.lottieWrapper{
  padding:50px;
  text-align: center;
  background-color: #120f2e;
  border-radius: var(--radius);
  box-sizing: border-box;
  width: 50%;
  margin: 300px auto;
  color: #fff;
  font-size: 15px;
}
.lottie{
  width: 300px;
  margin: auto;
  padding:20px 0px;
}

@media only screen and (max-width: 900px) {
  .lottieWrapper{
    font-size: 13px;
  }
  .spinnerContainer{
    padding: 50px 0px;
    box-sizing: border-box;
  }
}
