.categoriesBanner{
  height: 800px;
  display: flex;
  background-position-y: center;
  position: relative;
  background-size: cover !important; 
  background-repeat: no-repeat !important;
}
.categoriesBanner>div{
  width: 600px;
  margin: auto;
  padding:20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: bold;
  color: var(--white);
  letter-spacing: 2.5px;
}
.longDesc{
  text-align: center;
  font-size: 25px;
  padding:50px 0px;
}
.list{
  padding: 50px 0px;
  font-size: 20px;
}
.list p{
  padding: 0px;
  margin: 0px;
}
.list p:last-child{
  /* color: var(--color); */
  font-size: 30px;
  font-weight: bold;
}
@media only screen and (max-width: 800px) {
  .categoriesBanner{
    height: 600px;
    background-size: cover;
    margin-bottom: 20px;
  }

    .categoriesBanner>div{
        width: 80%;
    }
  .list{
    padding:5px 0px;
  }
  .list p:last-child,
  .longDesc{
    font-size: 17px;
    text-align: left;
  }
  .categoriesBanner p{
    text-align: center;
  }
}