.grid1 {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 50px;
  padding-top: 20px;
  margin-bottom: 20px;
}
.gridReverseSingle{
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 50px;
  padding-top: 20px;
  margin-bottom: 20px;
}

.gridReverseSingle> *:nth-child(2n) {
  flex-direction: row-reverse;
}

.grid2,
.gridTwo {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}
.grid12{
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 20px;
}
.gridCustom2 {
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-gap: 50px;
}
.grid3,
.gridActivities {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
}
.gridHeader{
  display: grid;
  grid-template-columns:  1fr 554px;
  grid-gap: 20px;
  width: 100%;
  align-items: center;
}
.grid4 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
}
.grid21 {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 20px;
}
.grid61{
  display: grid;
  grid-template-columns: 6fr 1fr;
  grid-gap: 20px;
}

.grid12,
.gridBanner12 {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 20px;
  align-items: center;
  height: 100%;
}

.grid6 {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 20px;
}
.grid5 {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 10px;
}

.gridCustom {
  display: grid;
  column-gap: 20px;
  row-gap: 30px;
}

.gridCustom a:first-child {
  grid-row-start: 1;
  grid-column-start: 1;
  grid-row-end: 2;
  grid-column-end: 3;
}

.gridCustom a:nth-child(2) {
  grid-row-start: 1;
  grid-column-start: 3;
  grid-row-end: 2;
  grid-column-end: 4;
}
.gridCustom a:nth-child(3) {
  grid-row-start: 2;
  grid-column-start: 1;
  grid-row-end: 3;
  grid-column-end: 2;
}
.gridCustom a:nth-child(4) {
  grid-row-start: 2;
  grid-column-start: 2;
  grid-row-end: 3;
  grid-column-end: 4;
}
.gridCustom a:nth-child(5) {
  grid-row-start: 3;
  grid-column-start: 1;
  grid-row-end: 4;
  grid-column-end: 3;
}
.gridCustom a:nth-child(6) {
  grid-row-start: 3;
  grid-column-start: 3;
  grid-row-end: 4;
  grid-column-end: 4;
}

.gridCustom a:nth-child(7) {
  grid-row-start: 3;
  grid-column-start: 1;
  grid-row-end: 4;
  grid-column-end: 3;
}
.gridCustom a:nth-child(8) {
  grid-row-start: 3;
  grid-column-start: 3;
  grid-row-end: 4;
  grid-column-end: 4;
}


.gridActivity {
  grid-template-areas:
    "h  h "
    "a1 a2"
    "f  f ";
  display: grid;
  grid-gap: 20px;
}

.gridActivity a:first-child {
  grid-area: h;
}

.gridActivity a:nth-child(2) {
  grid-area: a1;
}

.gridActivity a:nth-child(3) {
  grid-area: a2;
}

.gridActivity a:nth-child(4) {
  grid-area: f;
}
.gridActivity {
  grid-template-areas:
    "h  h "
    "a1 a2"
    "f  f ";
  display: grid;
  grid-gap: 20px;
}

.gridActivity a:first-child {
  grid-area: h;
  box-shadow: #af46fa 30px -90px 48px -43px inset;
}

.gridActivity a:nth-child(2) {
  grid-area: a1;
  box-shadow: #321eff 30px -90px 48px -43px inset;
}

.gridActivity a:nth-child(3) {
  grid-area: a2;

  box-shadow: #faa000 30px -90px 48px -43px inset;
}

.gridActivity a:nth-child(4) {
  grid-area: f;
  box-shadow: #2d8cff 30px -90px 48px -43px inset;
}
@media only screen and (max-width: 1025px) {
  /* big landscape tablets, laptops, and desktops */

  .grid3,
  .gridActivities {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }

  .grid6 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
  }
}

@media only screen and (max-width: 801px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */

  .gridActivity{
    grid-template-areas: 
    "h h "
    "a1 a1 "
    "a2 a2"
    "f f ";
  }

  .grid3 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }

  .gridCustom2 {
    display: grid;
    grid-gap: 20px;
    position: relative;
    align-items: center;
    grid-template-columns: 1fr;
  }

  .grid4 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }

  .grid6 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
  }
}

@media only screen and (max-width: 600px) {

  .gridCustom2,
  .grid61,
  .gridBanner12,
  .grid3,
  .grid2 {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }
  .grid12 {
    display: grid;
    grid-gap: 10px;
    position: relative;
    align-items: center;
    grid-template-columns: 1fr 2fr !important;
  }

  .grid4 {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }

  .grid6 {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
  }
  .grid5 {
    grid-template-columns: repeat(3, 1fr);
  }

  .gridCustom {
    row-gap: 20px;
    display: grid;
    column-gap: 0;
    grid-template-columns: 1fr;
  }
  .gridCustom {
    grid-template-columns: 1fr;
  }
  .gridCustom a{
    grid-row-start: initial !important;
    grid-column-start: initial!important;
    grid-row-end: initial!important;
    grid-column-end: initial!important;
  }
}
