.pbt50 {
  padding: 50px 0px;
}
.pbt100 {
  padding: 50px 0px;
}

.atractionsLink {
  font-size: 40px;
  text-decoration: none;
  font-weight: bold;
  color: #321eff;
  background: linear-gradient(
    90deg,
    #321eff,
    rgb(207, 203, 196),
    rgb(252, 252, 252),
    blue,
    blue,
    purple
  );
  background-size: 200% 200%;
  background-clip: text;
  color: transparent;
  -webkit-background-clip: text;

  -webkit-animation: Animation 10s ease infinite;
  -moz-animation: Animation 10s ease infinite;
  animation: Animation 10s ease infinite;
}
.atractionsLink:hover {
  color: white;
  transition: 500ms ease;
}

/* .reservationBars {
  display: flex;
  width: fit-content;
  margin: auto;
  list-style: none;
  padding-bottom: 50px;
}
.reservationBars li {
  padding: 0px 20px;
  position: relative;
  text-align: center;
  cursor: pointer;
}
.reservationBars li::before {
  content: url("");
  position: absolute;
  display: inline-block;
  width: 100%;
  height: 5px;
  margin-right: 5px;
  z-index: 9999;
  background-color: #120e2e;
  transform: skew(0deg);
  bottom: -10px;
  left: 0;
}
.reservationBars .active::before {
  background-color: #321eff;
} */
.cartWrapper {
  background-color: #120e2e;
  padding: 30px;
  border-radius: 5px;

}
.cartBox{
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px dashed #918DB8;
  padding-bottom: 30px;
}
.cartWrapper p,
.cartContainer h2 {
  margin: 0px;
}
.cartWrapper span {
  font-size: 12px;
  color: #E4E4E4;
}
.cartBox h1 {
  margin: 0px;
  padding: 5px 0px;
  color: #fff;
  font-size: 20px;
  font-weight: 300;
}
.cartHeader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 20px;
}
.cartHeader h2,
.cartHeader p {
  margin: 0px;
}
.cartFooter{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.reservationLayout h2,
.reservationLayout p {
  margin: 0px;
  padding: 0px;
}
.border {
  padding-bottom: 5px;
  display: flex;
  align-items: center;
}

.border span{
  width: 40px;
  height: 40px;
  border: 1px solid var(--white);
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  cursor: pointer;
  transition: 0.5s;
}
.border span:hover{
  background-color: var(--color);
  border-color: var(--color);
}
.slotContainer p{
  padding-bottom: 10px;
}
.slot span{
  font-size: 12px;
}
.emptyCart{
  color: #cecece;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 300;
  min-height: 300px;
}
.emptyCart>div{
  width: 70%;
}
.emptyCart p{
  font-size: 15px;
  font-weight: bold;
}
.close{
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.close+h1{
  font-size: 12px;
}
.close svg{
  width: 100%;
  width: 25px;
  height: 25px;
  color: #F03C44;
}
.btnWrapper{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.btnWrapper label{
  padding:20px 0px;
}
.btnWrapper button:last-child{
  border:1px solid #fff;
  background-color: transparent;
  color: #fff;
  font-weight: bold;
  transition: 0.5s;
}
.btnWrapper button:last-child:hover{
  border-color: var(--color);
  color: var(--color);
}
.btnWrapper a{
  color: #fff;
}
.reservationWrapper{
  background-color: #120E2E;
  padding: 20px 30px;
  margin-bottom: 20px;
  border-radius: var(--radius);
}
.reservationWrapper h1{
  margin: 5px 0px;
  font-size: 17px;
  letter-spacing: 0.3;
}
.reservationBoxes{
  margin:50px 0px;
}
.reservationWrapper ul{
  list-style: none;
  display: flex;
  padding:0px;
  margin:0px;
  margin-top: 20px;
}
.reservationWrapper ul li{
  border: 1px solid var(--color);
  padding:10px 20px;
  border-radius: var(--radius);
  font-size: 14px;
  margin-right: 5px;
}
.reservationDetails{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.price{
  font-size: 30px !important;
}
.noReservation{
  background-color: #120f2e;
  padding:20px;
  border-radius: var(--radius);
  width: 40%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

}
@keyframes Animation {
  0% {
    background-position: 10% 0%;
  }
  50% {
    background-position: 91% 100%;
  }
  100% {
    background-position: 10% 0%;
  }
}

@media only screen and (max-width: 800px) {
  .reservation {
    padding:50px 0px;
  }
  .atractionsLink{
    font-size: 30px;
  }
  .noReservation{
    width: fit-content;
  }
}