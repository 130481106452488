.categoriesBanner {
  padding: 100px 0px;
}
.categoriesData {
  border-left: 2px solid var(--white);
  padding-left: 30px;
}
.categoriesData h1 {
  margin: 0px;
}
.categoriesBanner p {
  white-space: pre-line;
}
.categoriesBanner p a {
  color: var(--main);
  font-weight: bold;
}

.reserveBtn {
  padding: 10px 30px;
  display: flex;
  margin: 20px 0px;
  text-decoration: none;
  border: 2px solid #af46fa;
  width: fit-content;
  border-radius: 35px;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: var(--white);
  transition: 0.5s;
}
.reserveBtn:hover {
  background-color: #af46fa;
}
.lightningWrapper {
  overflow: hidden;
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
}
.lightningImage {
  width: 80%;
  margin: auto;
  height: 450px;
  background: transparent;
  position: relative;
  border-radius: 5px;
  position: relative;
  display: flex;
}

.shadow {
  z-index: -1;
  position: absolute;
}

.lightningWrapper img {
  width: 100%;
  object-fit: contain;
}

.vidCon {
  display: none;
}

@media only screen and (max-width: 1281px) {
  /* hi-res laptops and desktops */
}

@media only screen and (max-width: 1025px) {
  /* big landscape tablets, laptops, and desktops */

  .categorieShop :first-child {
    width: 86px;
  }
}

@media only screen and (max-width: 801px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */

  .categoriesBanner {
    padding-top: 60px;
    padding-bottom: 0px;
  }

  .categoriesData {
    width: 90%;
    z-index: 1;
    margin: auto;
    display: flex;
    margin-top: 50px;
    margin-bottom: 50px;
    grid-column: 2 span;
    align-content: center;
    flex-direction: column;
    justify-content: center;
  }

  .lightningImage {
    top: 0;
    left: 0;
    width: 100%;
    opacity: 0.3;
    position: absolute;
  }

  .vidCon {
    display: block;
    width: fit-content;
  }
  .lightningWrapper div {
    padding-top: 60px;
  }
}

/* MOBILE */
@media only screen and (max-width: 600px) {
  .categoriesData {
    z-index: 2;
  }
  .lightningImage {
    height: 350px;
    position: absolute;
    opacity: 0.2;
    width: 100%;
    overflow: hidden;
  }
}
