.homeBanner {
  position: relative;
  height: calc(75vh - 93px);
}

.homeBannerImg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.homeBannerImg img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.bannerText {
  position: absolute;
  bottom: 120px;
  display: flex;
  flex-direction: column;
  width: 50%;
}

.bannerText h1 {
  font-weight: bold;
  color: var(--white);
  /* font-size: 85px; */
  width: 400px;
}

@keyframes move-gradient {
  0% {
    background-position: left;
  }
  100% {
    background-position: right;
  }
}

@keyframes move-gradient {
  0% {
    background-position: left;
  }
  100% {
    background-position: right;
  }
}
.bannerButtons {
  display: flex;
  align-items: center;
}
.bannerButtons a {
  margin-right: 20px;
  padding: 15px 25px;
  font-size: 18px;
}
.bannerButtons a:hover{
  background-color: var(--color);
  border-color: var(--color);
  color: var(--white);
}
@media only screen and (max-width: 1025px) {
  /* big landscape tablets, laptops, and desktops */

  .container {
    width: 80%;
  }
}

@media only screen and (max-width: 801px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */

  .homeBanner {
    position: relative;
    height: 60vh;
  }

  .homeBannerImg {
    width: 100%;
    height: 100%;
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: unset;
  }

  .homeBannerImg img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }
  .bannerText{
    width: 100%;
  }
}
/* MOBILE */
@media only screen and (max-width: 600px) {
  .homeBanner {
    position: relative;
    height: 50vh;
  }

  .homeBannerImg {
    width: 100%;
    height: 100%;
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: unset;
  }

  .homeBannerImg img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }
  .bannerText{
    width: 80%;
    bottom:0px;
  }
  .bannerText h1 {
    /* width: 200px; */
    /* font-size:50px !important; */

  }
  .bannerButtons a {
    font-size:13px;
    padding:12px 20px;
  }
}
