.input input,
.input select {
  background-color: #120f2e;
  border-radius: var(--radius);
  outline: none;
  border: 1px solid #120f2e;
  padding: 10px 15px;
  width: 100%;
  box-sizing: border-box;
  color: var(--white);
  font-size:14px;
}

.input input:-webkit-autofill,
.input input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

.input .error {
  color: var(--red);
  padding-left: 10px;
  font-size: 11px;
  display: flex;
  font-weight: bold;
}
.error {
  border-color: var(--red) !important;
}

.btnContainer {
  display: flex;
  justify-content: space-between;
}
.btnContainer select {
  border: 1px solid #efefef;
  outline: none;
  color: var(--black);
  background-color: #efefef;
  cursor: pointer;
  font-weight: bold;
  padding: 5px;
  border-radius: 8px;
  height: 30px;
  margin: 0px 2px;
}
.prevButton {
  padding: 10px;
  width: 30px;
  height: 30px;
  border: 1px solid #efefef;
  font-weight: bold;
  display: flex;
  color: var(--black);
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  cursor: pointer;
  margin: 0px 2px;
}
.input label {
  font-size: 12px;
  padding-left: 10px;
  /* color: #6b6b6b; */
}

@media only screen and (max-width: 600px) {
  .input input{
    font-size:17px;
  }
  .input span {
    font-size: 12px;
  }
}
