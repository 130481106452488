.videoText{
  display: flex;
  flex-direction: column;
}
.wrapper{
  margin-top: 100px !important;
}
.videoWrapper{
  position: relative;
  height: 280px;
  z-index: 2;
}
.text {
  text-decoration: none;
  color: var(--white);
}

.videoWrapper::before{
  content: url("https://hyper-active.com/assets/images/videoBck.svg");
  height: 220px;
  left: -150px;
  margin-right: 5px;
  -webkit-mask-image: linear-gradient(#000,transparent);
  mask-image: linear-gradient(#000,transparent);
  overflow: hidden;
  position: absolute;
  top: -20px;
  width: 100%;
  z-index: -1;
}

.circleBck{
  /* background-image: url('/assets/images/circleBck.png'); */
  /* background-image: url('./public/assets/images/circleBck.png'); */

  background-size: contain;
  background-repeat: no-repeat;
}
.priceList{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding:50px 0px;
}
.priceList p{
  font-size: 25px;
}
@media only screen and (max-width: 600px) {
  
  .videoWrapper{
    height: 230px;
  }
  .priceList{
    text-align: center;
    font-size: 20px;
  }
  .priceList p{
   text-align: center !important;
    font-size: 20px !important;
  }
}