
.userWrapper {
  padding: 50px 100px;
}

.userWrapperDif {
    padding: 0 100px;
}

.userWrapper form {
  display: flex;
  flex-direction: column;
  color: var(--white);
}
.loginWrapper form {
  width: 400px;
  margin: auto;
  padding: 20px 0px;
  display: flex;
  flex-direction: column;
}
.userWrapper form button[type="submit"] {
  width: 100%;
  margin: 30px auto;
  padding: 15px 80px;
}
.loginForm{
  width: 500px;
  padding:50px 0px;
  margin: auto;
}
.userWrapper form a {
  text-align: center;
  color: var(--color);
  text-decoration: none;
  font-weight: bold;
  font-size: 13px;
}
.logo {
  width: 200px;
  display: flex;
  position: relative;
}
.logo span{
  position: absolute;
  bottom: -10px;
  color: var(--white);
  right: 0;
  font-size: 12px;
}
.mobile{
  margin: 0px !important;
}
.logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.logo p{
  position: absolute;
  bottom: -2px;
  font-size: 11px;
  right: 0;
}
.registerForm  button[type="submit"] {
  width: 60%;
  margin: 30px auto;
}
.inputInfo {
  font-size: 13px;
  padding-left: 10px;
  margin: 0px;
  color: #e4e4e4;
}
.termsWrapper {
  width: 60%;
  padding: 10px 0px;
  margin: auto;
}
.termsWrapper div {
  margin: 10px 0px;
  display: flex;
  align-items: baseline;
}
.termsWrapper label {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 12px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.termsWrapper .error {
  color: var(--red) !important;
}
.termsWrapper input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #120f2e;
}
.error {
  border: 1px solid var(--red);
}

/* On mouse-over, add a grey background color */
.termsWrapper label:hover input ~ .checkmark {
  background-color: #120f2e;
}

/* When the checkbox is checked, add a blue background */
.termsWrapper label input:checked ~ .checkmark {
  background-color: #321eff;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.termsWrapper label input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.termsWrapper label .checkmark:after {
  left: 9px;
  top: 8px;
  width: 3px;
  height: 6px;
  border: solid #03001e;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.wrapperText{
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;

}
.wrapperText h2{
  font-size: 30px;
  font-weight: bold;
  margin: 0px;
}
@media only screen and (max-width: 900px) {
  .userWrapper{
    padding:50px;
  }
}
@media only screen and (max-width: 600px) {
    .userWrapperDif {
        padding: 0 20px;
    }
    .userWrapper{
        padding:50px 0px;
    }
    .logo {
        width: 200px;
        margin: auto;
    }
    .termsWrapper {
        width: 100%;
    }
    .loginForm,
    .loginForm form{
        width: 100%;
    }
    .logo p{
        bottom: -10px;
    }
}
