.bigCard{
  background-color: #120f2e;
  border-radius: var(--radius);
}
.bigCardImgContainer {
  width: 100%;
  height: 100%;
}
.bigCardInfoContainer{
  height: 100%;
  display: grid;
  grid-template-rows: 0fr 0fr 0fr 0fr;
}
.bigCardImgContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.bigCardInfoContainer h2 {
  font-size: 24px;
  margin: 15px 0px 0px 15px;
  color: var(--main);
}

.bigCardInfoContainer h4 {
  width: 90%;
  font-size: 15px;
  margin-left: 15px;
  margin: 15px 0 0 20px;
  white-space: pre-line;
  padding-bottom: 15px;
}

.bigCardInfoContainer p {
  width: 90%;
  font-size: 12px;
  white-space: pre-line;
  color: #E4E4E4;
  line-height: 2.2;
  margin: 0 0 0 15px;
  padding: 0 0 20px 0;
}

.bCardBtn {
  display: flex;
  width: 130px;
  padding: 10px;
  color: #fff;
  margin-left: 15px;
  align-items: center;
  margin-bottom: 20px;
  border-radius: 40px;
  text-decoration: none;
  border: solid 1px #fff;
  justify-content: space-between;
}

.bCardBtn svg {
  font-size: 28px;
  color: #f03c44;
}

.linkBtn{
  display: flex;
  padding: 15px;
  color: #FFF;
  margin-bottom: 20px;
  width: fit-content;
  align-items: center;
  border-radius: 35px;
  text-decoration: none;
  justify-content: center;
  background: transparent;
  border: solid 1px #FFF;
}

.linkBtn span{
  display: flex;
  font-size: 24px;
  margin-left: 5px;
  color: #F03C44;
}

@media only screen and (max-width: 1281px) {
  /* hi-res laptops and desktops */
}

@media only screen and (max-width: 1025px) {
  /* big landscape tablets, laptops, and desktops */

  .categorieShop :first-child {
    width: 86px;
  }

  .bCardBtn {
    top: 20px;
    position: relative;
  }
}

@media only screen and (max-width: 801px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */

  .bigCardContainers {
    height: 100%;
  }

  .bCardBtn {
    margin-bottom: 20px;
  }
  .bigCardInfoContainer p {
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .bigCardContainers {
    width: 100%;
    height: 100%;
  }

  .bigCardInfoContainer {
    position: relative;
    justify-content: center;
    padding-right: 10px;
  }

  .bigCardInfoContainer h2 {
    font-size: 20px;
  }

  .bigCardInfoContainer h4 {
    font-size: 14px;
  }
  .bigCardInfoContainer p {
    line-height: 1.6;
  }
}
