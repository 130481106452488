.speedScalesContainer table{
  border-collapse: collapse;
}

.speedScalesHeader{
  background-color: #4600BE;
}

.speedScalesHeader th{
  padding: 10px;
  border: solid 1px #120E2E;
}

tbody tr:nth-child(even){
  background-color: #120E2E;
}

.speedScalesBody td{
  min-width: 150px;
  padding: 20px 18px;
}

.dotCon :nth-child(even){
  margin: 0 2px;
}
@media only screen and (max-width: 600px) {
  .speedScalesContainer{
    font-size: 12px;
  }
  .speedScalesBody td{
    font-size: 11px;
  }
}