.interestWrapper p {
  font-size: 22px;
}
.activitiesInterest {
  display: flex;
  flex-wrap: wrap;
}
.round {
  border-radius: 20px;
  padding: 15px 20px;
  text-align: center;
  background-color: #120f2e;
  cursor: pointer;
  font-size: 18px;
  margin-right: 10px;
  margin-bottom: 10px;
  transition: 0.5s;
  width: fit-content;
}
.round:hover {
  background-color: var(--color);
}

@media only screen and (max-width: 600px) {
  .round{
    font-size: 14px;
  }
}