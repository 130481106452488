.mainWrapper {
  padding: 20px 40px;
  background-color: #120e2e;
  border-radius: var(--radius);
}

.mainImg {
  width: 100%;
  height: 300px;
  margin-top: 30px;
}

.mainImg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.smallImg {
  left: 0;
  bottom: 0;
  width: 150px;
  position: absolute;
}

.smallImg img {
  width: 100%;
  height: 100%;
}

.infoCon a {
  display: flex;
  color: #fff;
  cursor: pointer;
  padding: 13px 20px;
  align-items: center;
  border-radius: 40px;
  border: solid 1px #fff;
  background-color: transparent;
  text-decoration: none;
  width: fit-content;
  font-weight: bold;
  font-size: 14px;
}

.infoCon button svg {
  margin-left: 5px;
}
.numContainer div {
  padding: 10px 0;
}

.numContainer h1,
.numContainer p {
  margin: 0;
}
.red {
  color: #f03c44;
}

.blue {
  color: #2d8cff;
}

.yellow {
  color: #faa000;
}

.purple {
  color: #af46fa;
}
.darkBlue {
  color: #2d8cff;
}

@media only screen and (max-width: 1281px) {
  /* hi-res laptops and desktops */
}

@media only screen and (max-width: 1025px) {
  /* big landscape tablets, laptops, and desktops */
}

@media only screen and (max-width: 801px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */

  .bigCon{
    grid-column: 2 span;
  }

  .mainWrapper {
    max-width: 100%;
    width: unset;
  }

  .numContainer {
    min-width: 20%;
    grid-row: 2;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
    grid-column: 2 span;
  }

}

@media only screen and (max-width: 600px) {
  .mainImg {
    height: 180px;
  }
  .mainWrapper{
    padding: 0px;
  }
  .infoCon,
  .numContainer{
    padding:20px;
    box-sizing: border-box;
  }
  .numContainer div {
    width: calc(50% - 20px);
  }

  .numContainer {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .blue,
  .yellow,
  .purple,
  .darkBlue,
  .red {
    font-size: 22px;
  }
}
