.reservationDetails{
  display: flex;
  align-items: center;
  justify-content: space-between;

}
.reservationBoxes{
  margin:50px 0px;
  width: 80%;
  margin: auto;
  text-align: center;
}
.reservationWrapper{
  background-color: #120f2e;

  padding:30px;
  margin-bottom: 20px;
  border-radius: var(--radius);
}
.reservationWrapper ul{
  list-style: none;
  display: flex;
  padding:0px;
  margin:0px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom:1px solid #120E2E;

}
.reservationWrapper ul li{
  background: var(--color);
  padding:10px 30px;
  border-radius: var(--radius);
  font-size: 12px;
  margin-right: 5px;
}
.reservationDetails{
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  justify-content: space-between;
  text-align: initial;
}
.reservationDetailsFooter{
  display: flex;
  align-items: center;
  padding:10px 0px;
  justify-content: space-between;
  border-top: 1px solid var(--background);
}
.reservationDetailsFooter h2{
  font-weight: normal;
  font-size: 15px;
}
.reservationDetailsFooter h1,
.reservationDetails h2{
  margin: 0px;
  color: #e4e4e4;
  font-weight: normal;
}
.reservationDetails span{
  font-size:15px;
  color: #e4e4e4;
}
.reservationDetails p{
  font-size: 20px;
  margin: 3px;
}
.reservationCharge{
  display: flex;
  align-items: center;
  justify-content: left;
  margin-bottom: 20px;
}
.reservationCharge span{
  padding:10px 20px;
  background-color: var(--color);
  border-radius: var(--radius);
  margin-right: 10px;
  font-size:12px;
}

.noReservation{
  background-color: #120f2e;
  padding:20px;
  border-radius: var(--radius);
  width: 40%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

}
@keyframes Animation {
  0% {
    background-position: 10% 0%;
  }
  50% {
    background-position: 91% 100%;
  }
  100% {
    background-position: 10% 0%;
  }
}

@media only screen and (max-width: 800px) {
  .reservationBoxes{
    width: 100%;
  }
  .reservation {
    padding:50px 0px;
  }
  .atractionsLink{
    font-size: 30px;
  }
  .noReservation{
    width: fit-content;
  }
}