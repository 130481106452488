.singleCarousel {
  background-color: var(--background);
  /* background-image: linear-gradient(0deg, var(--main) 0%, var(--background) 100%); */
  background: radial-gradient(
    closest-side,
    var(--color),
    #0a0252,
    var(--background)
  );
  padding-bottom: 50px;
}
.singleCarouselWrapper {
  width: 800px;
  margin: auto;
  position: relative;
}
.carouselBackground {
  background-color: transparent !important;
  position: relative;
}
.carouselBackground div {
  background-color: transparent !important;
}

.carouselBackground img {
  width: 100%;
}
.carouselBackground .awssld__bullets {
  bottom: 50px !important;
}
.carouselBackground .awssld__bullets button {
  color: #fff !important;
  background-color: #fff !important;
}

@media only screen and (max-width: 801px) {
  .singleCarouselWrapper {
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .singleCarouselWrapper {
    width: 100%;
  }
}
