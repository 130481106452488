.radiantBanner {
  background-color: #ffcb43;
  background-image: linear-gradient(319deg, #ffcb43 0%, #ff6425 37%, #F03C44 100%);
    border-radius: var(--radius);
  position: relative;
  display: flex;
}
.appBanner{
  background-color: #00DBDE;
  background-image: linear-gradient(90deg, rgba(0,99,255,1) 0%, var(--main) 100%);
  position: relative;
  display: flex;
  border-radius: var(--radius);
  margin:50px 0px;
  height: 400px;

}
.radiantImage{
  width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
}
.appImage{
  width: 40%;
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  top: -70px;
  left:0px
}
.appImage img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.radiantImage img{
  width: 80%;
  height: 80%;
  object-fit: cover;
}
.radiantInfo{
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.appInfo{
  padding: 50px;
  padding-left: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  box-sizing: border-box;
}
.radiantInfo h1,
.appInfo h1{
  font-size: 50px;
  margin: 0px;
  font-weight: bold;
  letter-spacing: 0.1px;
  line-height: 60px;
}
.radiantInfo a{
  padding: 10px 25px;
  border-radius: var(--radius);
  color: white;
  border: 1px solid var(--white);
  outline: none;
  text-decoration: none;
  font-size: 14px;
  width: fit-content;
  cursor: pointer;
}

@media only screen and (max-width: 801px) {
  .radiantImage{
    position: absolute;
    opacity: .3;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
 .radiantImage img{
    width: 100%;
    height: 100%;
  }

  .radiantInfo{
    z-index: 1;
    grid-column: 2 span;
  }

}

/* MOBILE */
@media only screen and (max-width: 800px) {
  .appInfo{
    padding-left: 40%;
  }
  .appInfo h1{
    font-size: 35px;
    line-height: 45px;
  }
}
@media only screen and (max-width: 600px) {
  .appImage{
    width: 100%;
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    opacity: .3;
  }
  .appInfo{
    z-index: 1;
    padding: 20px;
    justify-content: center;
  }
  .appBanner{
    padding:0px;
    height: 295px;

  }
  .appInfo h1{
    font-size: 27px;
    line-height: 45px;
  }
  .appInfo p{
    font-size:13px;
  }
  .radiantInfo{
    padding: 0px;
  }
  .radiantInfo h1{
    font-size: 20px;
  }
  .radiantBanner :first-child {
    z-index: 2;
  }

  .radiantBanner {
    padding: 20px;
  }

  .radiantBanner div {
    width: 100%;
    height: 100%;
  }

  .radiantImage {
    top: 0;
    left: 0;
    width: 100%;
    opacity: 0.2;
    height: 100%;
    position: absolute;
  }

  .radiantBanner img {
    top: 0;
    left: 0;
    width: 100%;
    right: unset;
    height: 100%;
    object-fit: contain;
    transform: translateY(0);
  }
}
