.cardBody {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  background-size: 100%;
  background-repeat: no-repeat;
  margin: auto;
  text-decoration: none;
  color: var(--white);
}
.settings{
  position: absolute;
  top: 30px;
  right: -10px;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  border: 1px solid var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
}

.cardBody img{
  width: 100%;
}
.cardBody span{
  font-size: 35px;
  font-weight: 200;
}
.cardBody p{
  margin: 0px;
  padding:5px 0px;
}
.cardBody h1{
  font-size: 15px;
  font-weight: 200;
}
.cardBtn{
  padding:30px 0px;
}