.steps {
  display: flex;
  flex-direction: row;
  padding: 0px;
  margin: 30px auto;
  width: fit-content;
  list-style: none;
  width: 80%;
}
.steps li {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  font-size: 14px;
  width: 30%;
}

.steps li span {
  width: 100%;
  background-color: #120f2e;
  height: 5px;
  transition: 0.5s;
}
.active {
  background-color: var(--color) !important;
}

@media only screen and (max-width: 1000px) {
  .steps {
    width: 100%;
    padding: 50px 0px 0px 0px;
    justify-content: center;
  }
}
@media only screen and (max-width: 700px) {
  .steps p{
    display: none;
  }
}