.randomCardContainer{
    gap: 20px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.randomCardContainer a{
    color: #FFF;
    text-align: center;
    text-decoration: none;
    transition: ease-in-out .1s;
}

.randomCardContainer a:hover{
    scale: 1.02;
    
}

.randomCardContainer a img{
    opacity: .5;
    transition: ease-in-out .1s;
}

.randomCardContainer a:hover img{
    opacity: 1;
    /* border-bottom-left-radius: 0; */
    /* border-bottom-right-radius: 0; */
}

.randomCard{
    position: relative;
}

.randomCardImage img{
    width: 100%;
    height: 300px;
    object-fit: cover;
    overflow: hidden;
    border-radius: 20px;
}

@media only screen and (max-width: 801px)  { 
    /* tablet, landscape iPad, lo-res laptops ands desktops */ 
 
    .randomCardContainer{
        gap: 20px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }   

    .randomCardContainer a:first-child{
        grid-column: 1;
    }
    
    .randomCardContainer a:nth-child(2){
        grid-column: 2;
    }

    .randomCardContainer a:last-child{
        grid-column: 1/span 2;
    }

}
@media only screen and (max-width: 600px) {
  
    .randomCardContainer{
        gap: 20px;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
    }

    .randomCardContainer a:first-child{
        grid-column: 1;
    }
    
    .randomCardContainer a:nth-child(2){
        grid-column: 1;
    }

    .randomCardContainer a:last-child{
        grid-column: 1;
    }
    
}