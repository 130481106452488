.dateFooter {
  border-top: 1px solid #120e2e;
  margin-top: 20px;
  padding-top: 20px;
}
.dateWrapper {
  margin: 30px 0px !important;
}
.carouselDay {
  background-color: #120e2e;
  padding: 10px 20px;
  border-radius: 10px;
  margin: 10px;
  cursor: pointer;
}
.carouselDay h1 {
  margin: 0px;
  font-size: 18px;
}
.carouselDay span {
  font-weight: 200;
  font-size: 14px;
}
.corner .active {
  background-color: var(--color);
}
.borders {
  padding: 20px 0px;
  border-top: 1px dashed #120e2e;
  border-bottom: 1px dashed #120e2e;
  margin: 20px 0px;
  position: relative;
}

.slotContainer h2 {
  color: var(--white);
  font-size: 14px;
  font-weight: normal;
  padding-bottom: 10px;
  /* padding-left: 10px !important; */
  /* border-left: 2px solid var(--color); */
}
.moreInfo{
  padding:10px 0px !important;
  display: flex;
  align-items: center;
}
.moreInfo span{
  outline: none;
  color: white;
  cursor: pointer;
  border: 1px solid var(--color);
  background:var(--color);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 30px;
  margin-left: 10px;
  font-size: 13px;
}
.slot{
  text-align: center;
}
.slot span{
  font-size: 12px;
  text-align: center;
}
.slotBox {
  font-size: 16px;
  cursor: pointer;
  transition: 0.5s;
  border-radius: 5px;
  background-color: rgba(145, 141, 184, 0.05);
  transition: 0.2s;
  text-align: center;
  padding: 3px 0;
  color: rgba(255, 255, 255, 0.8);
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  transition: 0.5s;
}
.slotBox:hover {
  background-color: var(--color);
  color: var(--white);
}

.arrow {
  background-color: var(--color);
  color: var(--white);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  position: absolute;
  left: -10px;
  top: 50px;
  border-color: var(--color);
}
.borders .rightArrow {
  right: -10px;
  left: auto;
  /* background-color: red; */
}
.slotsWrapper{
  position: relative;
}
.slotsWrapper .slotContainer {
  animation-duration: 1s;
  animation-name: slidein;
  padding-bottom: 30px;
}

.slotsParams {
  display: flex;
  margin-bottom: 20px;
}
.slotsParams button {
  background-color: transparent;
  outline: none;
  color: white;
  cursor: pointer;
  border: 1px solid var(--color);
  padding: 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  margin: 5px;
  margin-left: 0;
}
.flex {
  display: flex;
  justify-content: space-between;
}
.paramsReservation span {
  font-size: 12px;
}
.activeTime {
  background-color: var(--color) !important;
}
.activeSlot {
  /* color: var(--color); */
  background-color: var(--color);
  cursor:default;
}
.error {
  color: var(--red);
  padding: 10px 0px !important;
  font-weight: bold;
}
.disable {
  color: #757575;
  cursor: default;
  opacity: 0.5;
}
.disable:hover {
  color: #757575;
}

.typeWrapper{
  margin:20px 0px;
}
.typeContainer{
  padding:20px;
  border-radius: var(--radius);
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: #120e2e;
  border: 1px solid #120e2e;
  border-radius: 10px;
  cursor: pointer;
  font-weight: bold;
  text-align: left;
  position: relative;
  padding-left:50px;
  color: #6a6692;
  transition: 0.5s;
}
.typeContainer div{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
}
.typeContainer div p{
  font-size: 12px;
}
.typeContainer span{
  position: absolute;
  left:15px;
  font-size:35px;
  color: #6a6692;
  transition: 0.5s;
}
.typeContainer:hover,
.typeContainer:hover span{
  color: var(--white);
}
.type{
  color: #6a6692;
}
.flexWrapper{
  display: flex;
  align-items: center;
  padding-bottom: 15px;
  cursor: pointer;
}
.themeNameWrapper{
  margin-bottom: 20px;
  font-size:25px;
  display: flex;
  color: var(--color);
}
.moreDetails{
  font-size: 12px;
  padding: 10px 0px;
}
.overlay{
  background-color: #020013;
  opacity: 0.5;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@keyframes slidein {
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
  }
}


@media only screen and (max-width: 600px) {
  .carouselDay h1 {
    font-size: 13px;
  }
}

